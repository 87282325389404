<template>
  <div class="bg-grey">
    <b-container fluid class="pt-4 job-listings">
      <b-row>
        <b-col id="bg-splash" class="col-md-12 py-5 mt-5 text-dark">
          <div class="col-11 mx-auto">
            <b-row class="bg-white rounded-lg shadow mx-md-5 mb-0">
              <b-col class="col-md-12 job-details-card py-3 mb-0">
                <div class="job-details-header">
                  <div>
                    <img :src="jobDetails.company_logo_path" :alt="jobDetails.company_long_name" width="50" height="50" />
                  </div>
                  <div>
                    <h6 class="grey-text">
                      {{ jobDetails.company_long_name }}
                    </h6>
                  </div>
                  <div class="grey-text">
                    <h6 class="d-flex">
                      <img src="@/assets/app-icons/clock.svg" width="16px" class="mr-2" />
                      {{ jobDetails.apply_at }} d ago (applied)
                    </h6>
                  </div>
                </div>
                <!--End Job Details Header-->
                <div class="job-details-content">
                  <h5 class="job-title fpn-b">
                    {{ jobDetails.job_title }}
                  </h5>
                  <div>
                    <h6 class="heading">Salary (Annually)</h6>
                    <h6 class="head-text">
                      <div class="d-flex">
                        <div class="mr-2">{{ jobDetails.currency_code }}</div>
                        <div class="pr-2">
                          {{ formattedNumber(jobDetails.job_salary_range_from) }}
                        </div>

                        <div class="pr-2">-</div>
                        <div class="mr-2">{{ jobDetails.currency_code }}</div>
                        <div>{{ formattedNumber(jobDetails.job_salary_range_to) }}</div>
                      </div>
                    </h6>
                  </div>
                  <div>
                    <h6 class="heading">Job Type</h6>
                    <h6 class="head-text">{{ jobDetails.job_type }}</h6>
                  </div>
                  <div>
                    <h6 class="heading">Location</h6>

                    <div class="d-flex job-location">
                      <p v-for="c of jobDetails.city" :key="c.city_id">
                        {{ c.city_name }}
                      </p>
                    </div>
                  </div>
                  <!-- <div>
                    <button
                      v-b-modal="'modal' + index"
                      class="btn btn-success btn-match d-flex align-items-center"
                      :id="'modal' + index"
                    >
                      97% Match &nbsp;<img
                        src="@/assets/app-icons/question-mark.svg"
                      />
                    </button>
                  </div> -->
                </div>
                <!--End Job Details Content-->

                <!-- <div class="job-details-footer pt-2">
                  <div class="left">
                    <h6 class="left-text border-right">
                      <span> SHARE </span>
                      <img
                        src="@/assets/app-icons/share.svg"
                        width="15px"
                        class="ml-2"
                      />
                    </h6>
                    <h6 class="left-text">
                      <span> SHORTLIST </span>
                      <img
                        src="@/assets/app-icons/bookmark.svg"
                        width="15px"
                        class="ml-2"
                      />
                    </h6>
                  </div>
                </div> -->
                <!--End Job Details Footer-->
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col class="col-12">
          <b-row class="bg-white rounded-lg shadow mx-md-5 px-4 mb-3 pt-3 mt-md-n4">
            <b-col class="col-12 pt-3">
              <div class="flex-between border-bottom pb-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="60px" />
                  <div>
                    <!-- <h5 class="fpn-b f-light">STEP 1</h5> -->
                    <h4 class="fpn-b mt-n2 mb-0">CV</h4>
                  </div>
                </div>
                <!-- <div class="bg-grey rounded-lg p-3 col-3">
                  <div class="d-flex align-items-center mb-2">
                    <div class="qst-icon mr-2">?</div>
                    <h5 class="fmp-b mb-0">TIPS</h5>
                  </div>

                  <p class="fs-12">
                    File should be in pdf format and no larger than 8mb in size
                  </p>
                </div> -->
              </div>
              <div class="d-flex py-3">
                <div class="resume-box px-4">
                  <div class="resume-doc" v-b-tooltip.hover :title="cvName">
                    <img src="@/assets/app-icons/pdf.svg" class="img-fluid mr-3" alt="pdf-icon" width="20px" />
                    <a style="cursor: pointer" @click="onClickUrl">{{ cvName ? cvName : "Applicant2020.pdf" }}</a>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col class="col-12 pt-4">
              <div class="flex-between border-bottom pb-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/video-online.svg" class="img-fluid pr-3" alt="cv-icon" width="70px" />
                  <div>
                    <!-- <h5 class="fpn-b f-light">STEP 2</h5> -->
                    <h4 class="fpn-b mt-n2 mb-0">Virtual Interview</h4>
                  </div>
                </div>
              </div>
              <div>
                <div v-for="(data, index) in viQ" :key="index" class="border-bottom pt-3">
                  <h5 v-if="data.wfc_id == 2">Q{{ index + 1 }}. {{ data.question }}?</h5>
                  <div class="bg-white rounded-lg">
                    <p v-if="data.answer"><b> Ans:</b> {{ data.answer }}</p>
                  </div>
                  <div class="bg-white rounded-lg">
                    <p v-if="data.question_option_title"><b> Ans:</b> {{ data.question_option_title }}</p>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col v-if="virtualVideoInterview && virtualVideoInterview.length" class="col-12 pt-4">
              <div class="flex-between border-bottom pb-3">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/video-online.svg" class="img-fluid pr-3" alt="cv-icon" width="70px" />
                  <div>
                    <!-- <h5 class="fpn-b f-light">STEP 3</h5> -->
                    <h4 class="fpn-b mt-n2 mb-0">Virtual Video Interview</h4>
                  </div>
                </div>
                <!-- <div class="bg-grey rounded-lg p-3 col-3">
                  <div class="d-flex align-items-center mb-2">
                    <div class="qst-icon mr-2">?</div>
                    <h5 class="fmp-b mb-0">TIPS</h5>
                  </div>
                  <p class="fs-12">
                    File should be in pdf format and no larger than 8mb in size
                  </p>
                </div> -->
              </div>
              <div class="py-3">
                <!-- <p class="f-light fs-14">
                  Make a relevant and impactful impression by answering this
                  video question in 30 seconds
                </p> -->
                <div v-for="(data, index) in virtualVideoInterview" :key="index">
                  <h5 v-if="data.wfc_id == 3">Q{{ index + 1 }}. {{ data.question }}?</h5>
                  <p v-if="data.wfc_id == 3" class="f-light fs-14"><b>Tips:</b> {{ data.question_tips }}</p>
                </div>
                <div class="ml-auto mr-auto w-50" v-if="videoAns != ''">
                  <!-- <CloudflareVideoPlayer
                    :video-id="videoAns"
                    :autoplay="false"
                  /> -->
                  <iframe v-if="videoAns"
                    :src="`https://iframe.videodelivery.net/${videoAns}/thumbnails/thumbnail.jpg?time=1s&height=500`"
                    style="border: none" height="500" width="600"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"></iframe>
                </div>
              </div>
            </b-col>
            <b-col v-if="jobDetails.eiq && jobDetails.eiq.length > 0" class="col-12">
              <div class="flex-between border-bottom pb-2">
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="60px" />
                  <div>
                    <!-- <h5 class="fpn-b f-light">STEP 5</h5> -->
                    <h4 class="fpn-b mt-n2 mb-0">EI Assessment Answer</h4>
                  </div>
                </div>
                <!-- <div class="bg-grey rounded-lg p-3 col-3">
                  <div class="d-flex align-items-center mb-2">
                    <div class="qst-icon mr-2">?</div>
                    <h5 class="fmp-b mb-0">TIPS</h5>
                  </div>
                  <p class="fs-12">
                    File should be in pdf format and no larger than 8mb in size
                  </p>
                </div> -->
              </div>
              <div class="py-3">
                <!-- <p class="f-light fs-14">
                  Answer some technical questions regarding your field
                </p> -->
                <div class="border-bottom py-3">
                  <h5></h5>
                  <div class="bg-white rounded-lg">
                    <div v-for="(data, index) in ei" :key="index" class="border-bottom pt-3">
                      <h5 class="font-weight-bolder" v-if="data.length">
                        {{ data[0].ei_type_name }}
                      </h5>
                      <div class="bg-white rounded-lg" v-for="(q, i) of data" :key="i">
                        <p>Q. {{ q.eiq_name }}</p>
                        <p>Ans. {{ q.ei_answer }}</p>
                      </div>
                      <!-- <div class="bg-white rounded-lg">
                        <p v-if="data.question_option_title">
                          <b> Ans:</b> {{ data.question_option_title }}
                        </p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicantJobServices from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import moment from "moment";
import { numeral } from "numeral";

import CloudflareVideoPlayer from "vue-cloudflare-video-player";

export default {
  components: { CloudflareVideoPlayer },
  data() {
    return {
      cv: null,
      cvName: null,
      jobDetails: {},
      temp_id: null,
      virtualInterview: [],
      virtualVideoInterview: [],
      ei: [],
      tempQ: [],
      videoAns: "",
      question: {},
      viQ: [],
      ans: {
        virtualInterview: [],
        virtualVideoInterview: [],
      },
    };
  },
  beforeMount() {
    this.getJobApplication();
    //this.getProfile();
    this.getJobCv();
  },
  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    getJobCv() {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        job_id: this.$route.params.job_id,
      };
      ApplicantProfileService.GetJobCv(payload).then((res) => {

        this.cv = res.data.data[0].user_job_cv_path;
        this.cvName = res.data.data[0].user_job_cv_name;
      });
    },
    getAppliedJobDetails(temp_id) {
      const job_id = this.$route.params.job_id;
      const user_id = +this.$cookies.get("user_id");
      ApplicantJobServices.GetApplicantAppliedJob({
        job_id,
        temp_id,
        user_id,
      }).then((res) => {
        console.log("res >>>>>>:>> ", res.data.data);
        this.question = res.data.data.response;
        this.ei = res.data.data.eiAns;
        console.log("res.data.data.apply_at :>> ", res.data.data.apply_at);
        if (res.data.data.apply_at.length) {
          const now = moment();
          const apply_at = moment(res.data.data.apply_at[0]);
          const diff = now.diff(apply_at, "days");
          this.jobDetails.apply_at = diff;
        }
        this.question.forEach((q) => {
          if (q.wfc_id === 3) {
            this.videoAns = q ? q.video_path : "";
          }
          if (q.wfc_id === 4) {
            this.tempQ.push(q);
          }
          if (q.wfc_id === 2) {
            this.viQ.push(q);
          }
        });
      });
    },
    getJobApplication() {
      const job_id = this.$route.params.job_id;
      ApplicantJobServices.GetJobApplication({ job_id }).then((job) => {
        console.log("job :>> ", job);
        this.jobDetails = job.data?.data[0];
        this.setTemplateId();
        this.jobDetails.question.forEach((q, i) => {
          this.jobDetails.question[i].option = this.jobDetails.question[i].option?.map((o) => {
            return {
              text: o.question_option_title,
              value: o.question_option_id,
            };
          });
          if (q.wfc_id == 2) {
            this.virtualInterview.push(this.jobDetails.question[i]);
            this.ans.virtualInterview = this.virtualInterview?.map((m) => {
              return {
                question_id: m.question_id,
                answer: "",
              };
            });
          }
          if (q.wfc_id == 3) {
            this.virtualVideoInterview.push(this.jobDetails.question[i]);
          }
        });
        console.log("this.jobDetails :>> ", this.jobDetails);
      });
    },
    setTemplateId() {
      this.jobDetails.question.forEach((q, i) => {
        if (q.wfc_id == 4) {
          this.temp_id = this.jobDetails.question[i].template_id;
        }
      });
      this.getAppliedJobDetails(this.temp_id);
    },
    getProfile() {
      let applicant_id = this.$cookies.get("user_id");
      ApplicantProfileService.getApplicantProfile({ applicant_id }).then((res) => {
        this.cv = res.data.data.user_cv[0];
        this.cvName = this.cv.user_job_cv_path.split("/")[5].split("_")[1];
        console.log("this.cv :>> ", this.cv);
      });
    },

    onClickUrl() {
      if (this.cv && this.cv) {
        window.open(this.cv, "_blank");
      }
    },

    onSubmit() {
      console.log("this.ans :>> ", this.ans);
      const ans = this.ans;
      const user_id = this.$cookies.get("user_id");
      const company_id = this.jobDetails.company_id;
      const job_id = this.jobDetails.job_id;
      ApplicantJobServices.AddJobAnswer({ ans, user_id, job_id, company_id })
        .then((res) => {

        })
        .catch((err) => {

        });
    },
  },
};
</script>

<style>
#bg-splash {
  position: relative;
  width: 100%;
  background-image: url("../../../assets/user-header.jpg");
  background-size: cover;
}
</style>
